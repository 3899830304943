import React from 'react';
import Layout from '../components/layout';
import Intro from '../components/intro';
import css from '@emotion/css';

const ErrorPage = () => (
  <Layout page="Whoops!">
    <Intro css={css`text-align: center; padding: 5rem;`}>
      <h2>We can't find the page you're looking for</h2>
      <p>Please return to the homepage to continuing browsing our site.</p>
    </Intro>
  </Layout>
);

export default ErrorPage;
